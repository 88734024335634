@import "./../theme";

nav {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;

  .logo {
    width: 100px;
    height: 100px;
  }
  
  &.navbar-light .logo {
    fill: $primary;
  }

  &.navbar-dark .logo {
    fill: $white;
  }
}