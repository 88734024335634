@import "./../theme";
@import "node_modules/bootstrap/scss/bootstrap.scss";

.clip-path {
  clip-path: url(#clip-path);
}
.start-gradient {
  stop-color: $primary;
  stop-opacity: 1;
}
.end-gradient {
  stop-color: $secondary;
  stop-opacity: 1;
}

#bird {
  opacity: 0.25;

  path:nth-of-type(1) {
    fill: none;
    stroke: $font-colour-alternate;
    stroke-width: 3px;
  }
  path:nth-of-type(n + 2) {
    fill: $font-colour-alternate;
  }
}

#hero {
  background-color: $primary;
  color: $font-colour-alternate;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  #hero-background {

    @media screen and (max-width: $wide-screen) {
        width: 60%;    
    }

    width: 50%;
    
  }

  #hero-content {

    @include media-breakpoint-up(sm) {  
      position: absolute;
    }    

    width: 100%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    div {
      margin: 0 $gutter-margin 0 $gutter-margin;

      h1 {
        font-weight: 600;
      }

      h2 {
        font-weight: 100;
      }
    }
  }

  .clear {
    clear: both;
  }
}
